import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import TextSection from "../components/text-section/text-section"
import { graphql } from "gatsby"
import Hero from "../components/hero/hero"
import DualColumns from "../components/dual-columns/dual-columns"
import CallingCard from "../components/calling-card/calling-card"

const PlayerRelations = props => {
  const data = props.data
  const members = data.members.edges
  return (
    <Layout>
      <SEO title="USLPA - Player Relations" />
      <Hero title="Player Relations" />
      <h2 className="chevron">Player Relations Team</h2>
      <section className="headshot-section">
        <DualColumns>
          {members.map((member, index) => (
            <CallingCard data={member.node} key={index} />
          ))}
        </DualColumns>
      </section>
      <div className="white-content">
        <TextSection>
          <p>
            Kyle Greig and Marco Micaletto make up the USLPA’s Player Relation
            Department. In their roles, they serve as the primary bridge between
            the USLPA Executive Committee and the overall USL Player pool. Kyle
            and Marco advocate and support players on a wide variety of issues.
            The Player Relations Department also communicates with the USL
            League Office on a weekly basis on behalf of players.
          </p>
          <p>
            Kyle is a current player in USL Championship and has played 9+ years
            in USL Championship and MLS. Kyle focuses on building relationships
            with Players and Team Representatives as well as tracking player
            movement during and out of season. In college, Kyle studied
            Bio-Chem, Nursing, and received a degree in Exercise Science from
            Benedictine College in Atchison, Kansas. Kyle enjoys being outdoors
            and spending time with his wife, Emilee, and two boys Mylo and
            Oliver.
          </p>
          <p>
            Marco is a 3rd year pro in USL League One. Marco focuses on building
            relationships with the Team Representatives and uses his experience
            as an international player to assist the other international
            Players. He is also a Spanish speaker and a good point of contact
            for Spanish speaking Players. Marco completed his Bachelors degree
            in Business, Communications and Spanish and recently finished a
            postgraduate degree in Sports Management. If he is not on the soccer
            field, Marco can be found on the golf course, probably in the rough,
            as he pursues his dream of making it on the Senior Tour.
          </p>
        </TextSection>
      </div>
      <h2 className="chevron">Frequently Asked Questions</h2>
      <TextSection>
        <p className="bold">
          What is the role of the Player Relations Department?
        </p>
        <p>
          The Player Relations Department exists to foster and maintain positive
          relationships amongst USLPA and its Players, as well as with USL Clubs
          and the USL League Office. As such, the Player Relations Department is
          the primary point of contact regarding any Player issue. This includes
          any issue that directly involves a Player and Club. The Player
          Relations Department maintains a consistent line of communication with
          Team Representatives across both the USL Championship and League One
          as well as with the USLPA Executive Directors and the USL League
          Office.
        </p>
        <p className="bold">
          How does the Player Relations Department operate?
        </p>
        <p>
          Once an issue has been brought to the Player Relations Department by a
          Player/Agent/Club/League, the Department begins working to resolve the
          issue. The Department leverages their relationships with the USL
          League Office and individual USL Clubs to determine the best course of
          action to resolve the Player issue. While determining and subsequently
          pursuing the best course of action, the Department maintains a
          constant dialogue with the Player to ensure that the Player is kept up
          to date throughout the process of resolving the issue. If further
          support or guidance is needed, the Department communicates directly
          with the USLPA’s Executive Directors and has the ability to leverage
          all of the USLPA’s capabilities.
        </p>
        <p className="bold">
          When should a Player contact the Player Relations Department?
        </p>
        <p>
          A Player should contact the Player Relations Department if they have
          ANY issue that they may have in which they need assistance, or support
          from the USLPA. Any contact will remain confidential, unless a Player
          has given the USLPA permission to disclose information as they work to
          assist the Player.
        </p>
        <p className="bold">
          How can I view the USLPA operating rules and bylaws?
        </p>
        <p>
          Current Players can view the Operating Rules and Bylaws by{" "}
          <a
            href="mailto:admin@uslplayers.org?subject=Bylaws and Operating Rules Request&body=Insert your First and Last Name here."
            className="bold"
          >
            requesting them from the USLPA via email.
          </a>{" "}
          Emails should include their first and last names so that the USLPA can
          verify membership.
        </p>
      </TextSection>
    </Layout>
  )
}

export default PlayerRelations

export const pageQuery = graphql`
  query {
    members: allPlayerRelationsJson {
      edges {
        node {
          name
          email
          headshot {
            alt
            src {
              childImageSharp {
                fixed(height: 250, width: 250) {
                  ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`
