 import React from "react"
 import PropTypes from "prop-types"
 import "./dual-columns.scss"
 
 const DualColumns = ({ children }) => {
 
   return <div className="container"><div className="dual-columns">{children}</div></div>
 }
 
 DualColumns.propTypes = {
   children: PropTypes.node.isRequired,
 }
 
 export default DualColumns
 